import Header from "./Header";
import React,{useState,useEffect} from "react";
import { Table } from "react-bootstrap";
function ListAdvertisements(){

    const [data,setData]=useState([]);
    useEffect( ()=>{
        
        fetchData();
    },[])
    console.warn("result",data)

    async function fetchData(){
        let result = await fetch("http://localhost:8000/api/listAdvertisement");
        result = await result.json();
        setData(result)
        }
    return (

        <div>
            <Header/>
            <h4>List Events Occuring in the Complex</h4>
            <div className="col-sm-8 offset-sm-2">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Price</th>
                            <th>Location</th>
                            <th>Operations</th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((adv)=>
                                <tr>
                                    <th>{adv.id}</th>
                                    <th>{adv.title}</th>
                                    <th>{adv.desc}</th>
                                    <th>{adv.price}</th>
                                    <th>{adv.location}</th>
                                    <th><span  className="delete">Register for the Event</span>

                                    </th>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>

            </div>
        </div>

    )


    
}
export default ListAdvertisements