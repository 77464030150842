import Header from './Header';
import { Card, Col, Container, Row,ListGroup, Button   } from 'react-bootstrap';
function Blog(){

    return(
      <div>
        <Header />
        <h1>
          Blog Page
        </h1>
        <div>
          <Card style={{ backgroundColor: '#eaccccc8' }}>
            <Card.Body>
              <Container>
              <Button style={{display:'table-cell',backgroundColor:'#f77062',height:'70px'}} href='http://mxk1021.uta.cloud/Blog/'>See Blog Pages</Button>

                <Row>
                  <Col xs={12} md={6}>
                    <Card.Title style={{ color: '#6445b0' }}>
                      <h1 className="large-font"><b>Why Visit us?</b></h1>
                    </Card.Title>
                    <Card.Text>
                      <p style={{ fontSize: '20px' }}><span style={{ fontSize: '36px' }}>Make your life easier</span> It is a pet-friendly, smoke-free apartment community that provides a unique rental experience,Each apartment for rent has been thoughtfully designed to offer you a comfortable home with must-have conveniences Featuring relaxing community amenities and common spaces that support whole-wellness living, including a resort-style swimming pool, on-site fitness center, and picnic areas.</p>
                    </Card.Text>
                  </Col>
                  <Col xs={12} md={6}>
                    <Card.Img src={require('../images/1.png')} alt="App1" width={335} height={300} />
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
          <Card className="blog-container" style={{ backgroundColor: '#eaccccc8' }}>
            <div className="d-flex justify-content-center">
              <Card.Img variant="top" src={require('../images/b1.jpg')} alt="App" width={335} height={300} />
            </div>
            <Card.Body className="Visit-1">
              <Card.Title className="xlarge-font"><b>Swimming Pool</b></Card.Title>
              <Card.Title className="large-font" style={{ color: 'rgb(55, 94, 75)' }}><b>It's a nature creature</b></Card.Title>
              <Card.Text style={{ fontSize: '20px' }}>When it comes to hardscaping your outdoor area, there’s nothing quite like a natural aesthetic. Handcrafted stone has emerged as a favored alternative to natural stone – and for good reason: While they emulate the timeless elegance of natural stone, these manufactured products are fully customizable, available in a variety of sizes, textures, and colors to perfectly accent any setting.</Card.Text>
             
            </Card.Body>
          </Card>
          <Card className="blog-container" style={{ backgroundColor: '#eaccccc8' }}>
            <div className="Visit-1">
              <Card.Title className="large-font" style={{ color: '#6445b0' }}>
                <b>Nature Lover?</b>
              </Card.Title>
              <Card.Text style={{ fontSize: '20px' }}>
                You can enjoy the 3 acres of garden area and feel as though you are in a botanical garden here. If you want to relax and have fun with your family or just need some space to yourself, this is the best place to do it. We have all the amenities you could possibly need in the garden for gatherings and lounging.
              </Card.Text>
            </div>
            <div className="Swim">
              <Card.Img src={require('../images/b3.jpg')} alt="App2" width={335} height={300} />
            </div>
          </Card>
          <Card style={{ backgroundColor: '#eaccccc8' }}>
            <Card.Body>
              <Card.Title style={{ color: '#6445b0' }}><b>Advantages</b></Card.Title>
              <ListGroup variant="flush">
                <ListGroup.Item style={{ fontSize: '20px' }}>Efficiency: By streamlining procedures and minimizing manual input, a new system may be created that boosts productivity and shortens turnaround times.</ListGroup.Item>
                <ListGroup.Item style={{ fontSize: '20px' }}>Accessibility: A wider range of users, including those with disabilities or language problems, might be catered for implementation of the fresh system, ensuring all individuals have equal access to it.</ListGroup.Item>
                <ListGroup.Item style={{ fontSize: '20px' }}>Integration: By integrating with other technology platforms and systems, the new approach could minimize effort duplication and promote data sharing.</ListGroup.Item>
                <ListGroup.Item style={{ fontSize: '20px' }}>Security: The newest security features could be incorporated into the new system's architecture to secure user data and lower the likelihood of cyberattacks.</ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
          <Card style={{ backgroundColor: '#eaccccc8' }}>
            <Card.Body>
              <Card.Title className="text-center" style={{ color: '#6445b0' }}>Disadvantages</Card.Title>
              <ListGroup>
                <ListGroup.Item style={{ fontSize: '20px' }}>Complexity: With a lot of features and functionalities, a website can be complicated. Users may find it challenging to browse and find what they're looking for as a result.</ListGroup.Item>
                <ListGroup.Item style={{ fontSize: '20px' }}>Maintenance: Updating and changing the website system frequently can be time-consuming and expensive. Regular maintenance, security upgrades, and technical assistance could be needed for the website.</ListGroup.Item>
                <ListGroup.Item style={{ fontSize: '20px' }}>Accessibility: It may be challenging for certain residents to use the online system efficiently because they lack internet access or lack technical expertise.</ListGroup.Item>
                <ListGroup.Item style={{ fontSize: '20px' }}>Security: Sensitive information including contact information, personal information will be present on the system. Thus, the website needs to have strong security measures in place to safeguard user data since it can be a target for cyberattacks.</ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
          <Card style={{ backgroundColor: '#eaccccc8' }}>
            <Card.Body>
              <Card.Title className="text-center" style={{ color: '#6445b0' }}>Room for Improvement</Card.Title>
              <ListGroup>
                <ListGroup.Item style={{ fontSize: '20px' }}>Simplify the User Interface: A user-friendly interface can be built for the website system to make it easier for users to navigate and find what they're looking for.</ListGroup.Item>
                <ListGroup.Item style={{ fontSize: '20px' }}>Frequent Maintenance: The website system should undergo routine maintenance, including upgrades and technical assistance, to guarantee seamless operation and address any potential problems.</ListGroup.Item>
                <ListGroup.Item style={{ fontSize: '20px' }}>Offer Training and Support: In order to ensure that all residents can use the website system efficiently, the system can offer training and assistance to users who may not be tech-savvy.</ListGroup.Item>
                <ListGroup.Item style={{ fontSize: '20px' }}>Strong Security Measures: To safeguard user data from cyber threats, the website system should have strong security measures in place, such as SSL encryption, two-factor authentication, and frequent security audits.</ListGroup.Item>
                <ListGroup.Item style={{ fontSize: '20px' }}>Foster Real-Life Communication: The website system can be designed to encourage real-life communication and interaction among residents, such as hosting events or facilitating community discussions.</ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
          <div className="blog-container" style={{ backgroundColor: '#eaccccc8' }}>
          </div>
        </div>
      </div>
    )
}
export default Blog;