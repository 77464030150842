
import './App.css';

// import Header from './pages/Header';
import Login from './pages/Login';
import About from './pages/About';
import Services from './pages/Services';
import Home from './pages/Home';
import Blog from './pages/Blog';
import { BrowserRouter,Route,Routes } from 'react-router-dom';
import Contact from './pages/Contact';
import Register from './pages/Register';
import BuildingManager from './pages/BuildingManager';
import ListManagers from './pages/ListManagers';
import AddManagers from './pages/AddManagers';
import UpdateManagers from './pages/UpdateManagers';
import ListResidents from './pages/ListResidents';
import ResidentManager from './pages/ResidentManager';
import UpdateResidents from './pages/UpdateResidents';
import VisitorManager from './pages/VisitorManager';
import ListVisitors from './pages/ListVisitors';
import UpdateVisitors from './pages/UpdateVisitors';
import RegResidents from './pages/RegResidents';
import ListTimings from './pages/ListTimings';
import Join from './pages/Join';
import EventReg from './pages/EventReg';
import Pay from './pages/pay';
import PoolManager from './pages/PoolManager';
import ListPools from './pages/ListPools';
import AddPools from './pages/AddPools';
import UpdatePools from './pages/UpdatePools';
import ListPoolTimings from './pages/ListPoolTimings';
import UpdatePoolTimings from './pages/UpdatePoolTimings';
import GardenManager from './pages/GardenManager';
import AddGardens from './pages/AddGardens';
import ListGardens from './pages/ListGardens';
import UpdateGardens from './pages/UpdateGardens';
import UpdateGardenTimings from './pages/UpdateGardenTimings';
import ListGardenTimings from './pages/ListGardenTimings';
import SecurityManager from './pages/SecurityManager';
import ListSecurity from './pages/ListSecurity';
import AddSecurity from './pages/AddSecurity';
import UpdateSecurity from './pages/UpdateSecurity';
import ListShiftTimings from './pages/ListShiftTimings';
import AddShiftTimings from './pages/AddShiftTimings';
import UpdateShiftTimings from './pages/UpdateShiftTimings';
import AdminManager from './pages/Admin';
import PiechartSecurity from './pages/PiechartSecurity';
import PiechartBuild from './pages/PiechartBuild';
import PoolAppointment from './pages/PoolAppointment';
import GardenAppointment from './pages/GardenAppointment';
import Advertisement from './pages/Advertisement';
import ListAdvertisements from './pages/ListAdvertisement';
import ForgotPassword from './pages/ForgotPassword';
import Directions from './pages/Directions';
import PiechartPool from './pages/PieChartPool';
import PieChartGarden from './pages/PieChartGarden';
import Footer from './pages/Footer';







function App() {
  return (
    <div className="App">
      
      <BrowserRouter>
      
      {/* <Header/> */}
     
      <Routes>
      <Route path="/Login" element={<Login />} />
      <Route path="/Register" element={<Register />} />
      <Route path="/" element={<Home />} />
      <Route path="/About" element={<About />} />
      <Route path="/Services" element={<Services />} />
      <Route path="/Blog" element={<Blog />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/BuildingManager" element={<BuildingManager />} />
      <Route path="/ResidentManager" element={<ResidentManager/>} />
      <Route path="/VisitorManager" element={<VisitorManager/>} />
      <Route path="/PoolManager" element={<PoolManager/>} />
      <Route path="/GardenManager" element={<GardenManager/>} />
      <Route path="/SecurityManager" element={<SecurityManager/>} />
      <Route path="/ListPools" element={<ListPools/>} />
      <Route path="/AddPools" element={<AddPools/>} />

      <Route path="/ListManagers" element={<ListManagers />} />
      <Route path="/listGardens" element={<ListGardens />} />
      <Route path="/listSecurity" element={<ListSecurity />} />
      <Route path="/ListTimings" element={<ListTimings />} />
      <Route path="/ListPoolTimings" element={<ListPoolTimings />} />
      <Route path="/ListGardenTimings" element={<ListGardenTimings />} />
      <Route path="/ListShiftTimings" element={<ListShiftTimings />} />
      <Route path="/ListResidents" element={<ListResidents/>} />
      <Route path="/ListVisitors" element={<ListVisitors/>} />
      <Route path="/AddManagers" element={<AddManagers />} />
      <Route path="/AddShiftTimings" element={<AddShiftTimings />} />
      <Route path="/AddGardens" element={<AddGardens />} />
      <Route path="/AddSecurity" element={<AddSecurity />} />
      <Route path="/RegResidents" element={<RegResidents />} />
      {/* <Route path="/AddResident" element={<AddResident />} /> */}
      <Route path="/UpdateManagers/:id" element={<UpdateManagers />} />
      <Route path="/UpdatePools/:id" element={<UpdatePools />} />
      <Route path="/UpdatePoolTimings/:id" element={<UpdatePoolTimings />} />
      <Route path="/UpdateGardenTimings/:id" element={<UpdateGardenTimings />} />
      <Route path="/UpdateShiftTimings/:id" element={<UpdateShiftTimings />} />
      <Route path="/UpdateResidents/:id" element={<UpdateResidents/>} />
      <Route path="/UpdateVisitors/:id" element={<UpdateVisitors/>} />
      <Route path="/UpdateGardens/:id" element={<UpdateGardens/>} />
      <Route path="/UpdateSecurity/:id" element={<UpdateSecurity/>} />
      <Route path="/Join" element={<Join/>} />
      <Route path="/EventReg" element={<EventReg/>} />
      <Route path="/Pay" element={<Pay/>} />
      <Route path="/AdminManager" element={<AdminManager/>} />
      {/* <Route path="/PieChart" element={<PieChart/>} /> */}
      <Route path="/PiechartSecurity" element={<PiechartSecurity/>} />
      <Route path="/PiechartBuild" element={<PiechartBuild/>} />
      <Route path="/PoolAppointment" element={<PoolAppointment/>} />
      <Route path="/GardenAppointment" element={<GardenAppointment/>} />
      <Route path="/Advertisement" element={<Advertisement/>} />
      <Route path="/ListAdvertisements" element={<ListAdvertisements/>} />
      <Route path="/ForgotPassword" element={<ForgotPassword/>} />
      <Route path="/Directions" element={<Directions/>} />
      <Route path="/PieChartPool" element={<PiechartPool/>} />
      <Route path="/PieChartGarden" element={<PieChartGarden/>} />
     




      </Routes>
      <Footer/>
     

      </BrowserRouter>
      
      
    </div>
  );
}

export default App;
