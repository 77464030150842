import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col, Alert, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Header from "../pages/Header";

function Login() {
  const [loginStatus, setLoginStatus] = useState("none");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("user-info")) {
      navigate("/");
    }
  }, [navigate]);

  async function handleLogin() {
    console.warn(email, password);
    const item = { email, password };
    console.warn(item);
    const response = await fetch("http://localhost:8000/api/login", {
      method: "POST",
      headers: {
        'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(item),
    });

    if (response.ok) {
      const data = await response.json();
      localStorage.setItem("user-info", JSON.stringify(data));
      const userType = data.userType;
      const allowedUserTypes = [
        "Resident",
        "Pool",
        "Admin",
        "Garden",
        "Visitor",
        "Security",
        "Building",
      ];

      if (allowedUserTypes.includes(userType)) {
        window.localStorage.setItem("user_object", JSON.stringify(data));
        navigate(`/${userType}Manager`);
      } else {
        setLoginStatus("failed");
      }
    } else {
      setLoginStatus("failed");
    }
  }

  function handleSignup() {
    navigate("/Register");
  }

  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col sm={{ span: 6, offset: 3 }}>
            <Card className="mt-5">
              <Card.Body>
                <h1 className="text-center">Login Page</h1>
                {loginStatus === "failed" && (
                  <Alert variant="danger" className="my-3">
                    Login failed. Please try again.
                  </Alert>
                )}
                <Form>
                  <Form.Group controlId="formBasicEmail"><br/>
                    <Form.Control
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword"><br/>
                    <Form.Control
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                    />
                  </Form.Group>

                  <Button
                    variant="primary"
                    size="sm"
                    className="my-3"
                    onClick={handleLogin}
                  >
                    Log In
                  </Button>

                  <Button
                    variant="secondary"
                    size="sm"
                    className="my-3 mx-2"
                    onClick={handleSignup}
                  >
                    Sign Up
                  </Button>
                  <Link to="/ForgotPassword"> Forgot Password</Link>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
