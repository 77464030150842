
import Carousel from 'react-bootstrap/Carousel';
import { Card, Button } from 'react-bootstrap';
import Header from "./Header";

function Home(){
    return(
        <div>
           <Header/>
            <div className="container" style={{ height: '2500px' }}>
                <Card style={{ backgroundColor: '#ffffffa6' }}>
                    <Card.Body>
                    <div style={{ color: '#f77062' }}>
                            <h1>Welcome To Terrazas de Guacuco</h1>
                        </div>
                        <Card.Text>
                            A luxurious ground floor apartment for 5 people, recently renovated,
                            steps from Playa Guacuco. Enjoy two huge pools, a restaurant,
                            bodegón, tennis court, beautiful walking and cycling spaces, and all
                            the comfort you need for an unforgettable vacation. We offer free
                            WiFi, electric shutters, 2 TVs with Netflix, Star+, Disney+, HBO
                            MAX, AppleTV and Prime Video, surfboards and bodyboards, bicycles,
                            barbecue and everything you need to have an amazing time.
                        </Card.Text>
                        <Button href='/Services' variant="primary">Explore Now</Button>
                    </Card.Body>
                </Card>
                <style dangerouslySetInnerHTML={{ __html: "\n    * {\n      box-sizing: border-box;\n    }\n    \n    /* Create two equal columns that floats next to each other */\n    .column {\n      float: left;\n      width: 100%;\n      padding: 100px;\n      height: 150vh; /* Should be removed. Only for demonstration */\n    }\n    \n    /* Clear floats after the columns */\n    .row:after {\n      content: \"\";\n      display: table;\n      clear: both;\n    }\n    " }} />

                <Card>
                    <Card.Body>

                        <h2>This Space consists of:</h2>
                        <Card.Text>
                            Ground floor apartment for 5 people, recently renovated, with covered
                            terrace and electrical backup. All the luxury and comfort and steps
                            from Playa Guacuco. Two huge pools, restaurant, bodegón, tennis court,
                            beautiful spaces for walking and cycling. We have WiFi, electric
                            shutters, 2 TVs with Netflix, Star+, Disney+, HBO MAX, AppleTV and
                            Prime Video, surfboards and bodyboards, bicycles, barbecue and
                            everything for an unforgettable vacation.
                        </Card.Text>
                        <Carousel>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={require('../images/G2.jpg')}
                                    alt="First slide"
                                />
                                <Carousel.Caption>
                                    <h3>The Complex You want to Live</h3>
                                    <p>The Dream complex you want to live in with your family and friends.</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={require('../images/G4.jpg')}
                                    alt="Second slide"
                                />
                                <Carousel.Caption>
                                    <h3>Amenities in the Complex</h3>
                                    <p>The Amenities available in the complex to enjoy .</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={require('../images/G5.jpg')}
                                    alt="Third slide"
                                />
                                <Carousel.Caption>
                                    <h3>The Interior of Appartment</h3>
                                    <p>The view for your appartments interior.</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={require('../images/G7.jpg')}
                                    alt="Fourth slide"
                                />
                                <Carousel.Caption>
                                    <h3>The View You get from the Appartment</h3>
                                    <p>The View from the appartment to the complex from your home.</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                        
                    </Card.Body>
                </Card>
                <div className="row" style={{ backgroundColor: '#0000002d' }}>
                    <div className="col-md-6">
                        <Card style={{height:'355px', backgroundColor: '#171515a3', border: 'none' }}>
                            <Card.Body style={{ color: '#fff' }}>
                                <Card.Title>Contact Us</Card.Title>
                                <Card.Text>
                                    <strong>Address:</strong>
                                    <br />Condominio Las Terrazas de Playa Guacuco,
                                    <br /> 6311, Nueva Esparta, Venezuela
                                    <br /><strong>Phoneno:</strong>
                                    <br />+584123530852
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-6">
                        <Card style={{ backgroundColor: '#171515a3', border: 'none' }}>
                            <Card.Body>
                                <Card.Title style={{color:'#ffff'}}>Find Us on Google Maps</Card.Title>
                                <Card.Text>
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3915.7939538051837!2d-63.81873978519668!3d11.054069292133498!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c31904b3395178b%3A0xf68635a7af15382c!2sCondo%20Terrazas%20de%20Guacuco!5e0!3m2!1sen!2sus!4v1676482304304!5m2!1sen!2sus" title="google Maps"
                                        width="120%"
                                        height="250"
                                        allowFullScreen=""
                                        loading="lazy" referrerPolicy="no-referrer-when-downgrade">
                                    </iframe>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                </ div>
            </div>
    )
}
export default Home;