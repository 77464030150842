import Header from "./Header";
import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";


function PoolManager(){


      

    

    return(

        <div>
            <Header/>
            <h1>Pool Manager</h1>
            <div className="col-sm-6 offset-sm-3"><br/> <br/> <br/>
            <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/addpoolmanager.jpg')}/>
                    <Card.Body>
                        <Card.Title>Add Pool Managers</Card.Title>
                        <Card.Text>
                           Add Pool Managers for the Pools in the Complex
                        </Card.Text>
                        <Button href="/AddPools" variant="primary">Add Pool Manager</Button>
                    </Card.Body>
                </Card>
            <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/listpoolmanagers.jpg')}/>
                    <Card.Body>
                        <Card.Title>List of Pool Managers</Card.Title>
                        <Card.Text>
                           List of all Pool Managers of the pools in the complex
                        </Card.Text>
                        <Button href="/ListPools" variant="primary">List Pool Manager</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/listresidents.jpg')}/>
                    <Card.Body>
                        <Card.Title>List of Residents</Card.Title>
                        <Card.Text>
                        List of all Residents Accessed the Pool in the complex
                        </Card.Text>
                        <Button href="/ListResidents" variant="primary">List Residents</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/listvisitor.png')}/>
                    <Card.Body>
                        <Card.Title>List of Visitors</Card.Title>
                        <Card.Text>
                           List of all Visitors Accessed the Pool in the complex
                        </Card.Text>
                        <Button href="/ListVisitors" variant="primary">List Visitors</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/timings.jpg')}/>
                    <Card.Body>
                        <Card.Title>List of Pool Timings</Card.Title>
                        <Card.Text>
                          List of all the Pool timings in the complex.
                        </Card.Text>
                        <Button href="/ListPoolTimings" variant="primary">List Timings</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/adv.jpg')}/>
                    <Card.Body>
                        <Card.Title>Event Advertisements</Card.Title>
                        <Card.Text>
                          Create a New Event Advertisement for complex.
                        </Card.Text>
                        <Button href="/Advertisement" variant="primary">Create Advertisement</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/reports.png')}/>
                    <Card.Body>
                        <Card.Title>Generate Reports</Card.Title>
                        <Card.Text>
                        Generate Reports for all Residents and Visitors in the pool                        </Card.Text>
                        <Button href="/PieChartPool" variant="primary">Generate Reports</Button>
                    </Card.Body>
                </Card>
                <Link id='chat-button' to="https://axa4926.uta.cloud/login.php" target="_blank">Chat with us</Link>


            
            </div>

        </div>

    )
  
}
export default PoolManager