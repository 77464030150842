import { Navbar,Nav,Container, NavDropdown } from "react-bootstrap";
import {  useNavigate } from "react-router-dom";
function Header(){
  const Navigate= useNavigate();
  let user = JSON.parse(localStorage.getItem("user-info"))
 function logOut(){
  localStorage.clear()
  Navigate("/Login")
 }
 function UserType(){
  Navigate(`/${user.userType}Manager`);
 }

    return(
        <div>
            <h1>
            <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/">Terrazas</Navbar.Brand>
          <Nav className="navbar_wrapper a">
                {
                  localStorage.getItem('user-info') ?
                    <>
                      <Nav.Link href="/"> Home</Nav.Link>
                      <Nav.Link href="/About"> About</Nav.Link>
                      <Nav.Link href="/Services"> Services</Nav.Link>
                      <Nav.Link href="/Contact"> Contact Us</Nav.Link>
                      <Nav.Link href="/Blog"> Blog</Nav.Link>

                     
                    </>
                    :
                    <>
                      <Nav.Link href="/About"> About</Nav.Link>
                      <Nav.Link href="/Blog"> Blog</Nav.Link>

                      <Nav.Link href="/Login"> Login/Registration</Nav.Link>
                    </>

                }
          </Nav>
          {localStorage.getItem('user-info') ?
          <Nav>
            <NavDropdown title={user && user.name}>
                <NavDropdown.Item onClick={UserType}>
                  {user.userType}
                </NavDropdown.Item>
                <NavDropdown.Item onClick={logOut}>
                  Log Out
                </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          :null}
        </Container>
      </Navbar>
            </h1>

        </div>
    )
}
export default Header;