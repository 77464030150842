import Header from "./Header";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';

function UpdateGardens(props) {
  const [garden, setGarden] = useState({ name: '', email: '', mobile: '' });
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    async function fetchData() {
      if (id) {
        let result = await fetch(`http://localhost:8000/api/getGarden/${id}`);
        result = await result.json();
        setGarden(result);
      }
    }
    fetchData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(`http://localhost:8000/api/updateGarden/`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(garden)
    });
    const data = await response.json();
    console.log(data); // for debugging purposes
    navigate('/ListGardens');
  };

  return (
    <div>
      <Header />
      <h1>Update Garden Managers</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input type="text" value={garden.name} onChange={(e) => setGarden({ ...garden, name: e.target.value })} />
        </label>
        <br />
        <label>
          Email:
          <input type="email" value={garden.email} onChange={(e) => setGarden({ ...garden, email: e.target.value })} />
        </label>
        <br />

        <label>
          Phone:
          <input type="tel" value={garden.mobile} onChange={(e) => setGarden({ ...garden, mobile: e.target.value })} />
        </label>
        <br />
        <button type="submit">Update Manager</button>
      </form>
    </div>
  );
}

export default UpdateGardens

