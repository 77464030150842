import Payment from "./Payment";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


const stripePromise = loadStripe('https://api.stripe.com');

function Pay(){
    return(
        <div>
            <Elements stripe={stripePromise}>
                <Payment />
            </Elements>

        </div>
    )
}
export default Pay
