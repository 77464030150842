import Header from "./Header";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';

function UpdateResidents(props) {
  const [resident, setResidents] = useState({ name: '', email: '', mobile: '' });
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    async function fetchData() {
      if (id) {
        let result = await fetch(`http://localhost:8000/api/getResident/${id}`);
        result = await result.json();
        setResidents(result);
      }
    }
    fetchData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(`http://localhost:8000/api/updateResident`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(resident)
    });
    const data = await response.json();
    console.log(data); // for debugging purposes
    navigate('/ListResidents');
  };

  return (
    <div>
      <Header />
      <h1>Update Residents</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input type="text" value={resident.name} onChange={(e) => setResidents({ ...resident, name: e.target.value })} />
        </label>
        <br />
        <label>
          Email:
          <input type="email" value={resident.email} onChange={(e) => setResidents({ ...resident, email: e.target.value })} />
        </label>
        <br />

        <label>
          Phone:
          <input type="tel" value={resident.mobile} onChange={(e) => setResidents({ ...resident, mobile: e.target.value })} />
        </label>
        <br />
        <button type="submit">Update Residents</button>
      </form>
    </div>
  );
}

export default UpdateResidents;

