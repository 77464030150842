import Header from "./Header";
import { useState } from "react";
import { Link } from "react-router-dom";

function AddManagers(){

    const [name,setName]=useState("");
    const [email,setEmail]=useState("");
    const [manager_type,setManager]=useState("");
    const [mobile,setMobile]=useState("");

    async  function addManager(){
        console.warn(name,email,manager_type,mobile)
        const formData = new FormData();
        formData.append('name',name);
        formData.append('email',email);
        formData.append('manager_type',manager_type);
        formData.append('mobile',mobile);
        let result = await fetch("http://localhost:8000/api/addManager",{
        method:'POST',
        body:formData
        
    });
    alert("Data has been saved")

    }

    return(

        <div>
            <Header/>
            <h1>Building Manager</h1>
            <div className="col-sm-6 offset-sm-3">
                <input type="text" value={name} className="form-control" onChange={(e)=>setName(e.target.value)} placeholder="Name"/> <br/>
                <input type="text" value={email} className="form-control" onChange={(e)=>setEmail(e.target.value)} placeholder="email"/> <br/>
                <input type="text" value={manager_type} className="form-control" onChange={(e)=>setManager(e.target.value)} placeholder="manager_type"/> <br/>
                <input type="text" value={mobile} className="form-control" onChange={(e)=>setMobile(e.target.value)} placeholder="mobile"/> <br/>
                <button style={{"marginRight":"15px"}} onClick={addManager} className="btn btn-primary">Add Manager</button>
                <Link to={'/BuildingManager'}>
                <button className="btn btn-primary">Go Back</button>
                </Link>            </div>

        </div>

    )
  
}
export default AddManagers