import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Header from "./Header";

function EventReg() {


    const [title,setTitle]=useState("");
    const [desc,setDesc]=useState("");
    const [price,setFlat]=useState("");
    const [location,setEvent]=useState("");

    async  function addResidentEve(){
        console.warn(title,desc,price,location)
        const formData = new FormData();
        formData.append('title',title);
        formData.append('desc',desc);
        formData.append('price',price);
        formData.append('location',location);
      
        let result = await fetch("http://localhost:8000/api/addAdvertisement",{
        method:'POST',
        body:formData
        
    });
    alert("Data has been saved")

    }
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  return (
    <>
      <Header />
      <div className="d-flex flex-column align-items-center">
        <h1>Event Advertisements</h1>
        <Form
          style={{ width: "20rem", paddingTop: "2rem" }}
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <Form.Group>
            <Form.Label>Title</Form.Label>
            <Form.Control
            value={title} className="form-control" onChange={(e)=>setTitle(e.target.value)}
              type="text"
              pattern="[A-Za-z ]+"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid event title name (letters and spaces only).
            </Form.Control.Feedback>
          </Form.Group>
          

          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control value={desc} className="form-control" onChange={(e)=>setDesc(e.target.value)} type="text" required />
            <Form.Control.Feedback type="invalid">
              Please enter a valid Description.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Price</Form.Label>
            <Form.Control
            value={price} className="form-control" onChange={(e)=>setFlat(e.target.value)}
              type="text"
              pattern="[$0-9]+"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid price (letters and spaces only).
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Location</Form.Label>
            <Form.Control
            value={location} className="form-control" onChange={(e)=>setEvent(e.target.value)}
              type="text"
              pattern="[A-Za-z ]+"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid Location.
            </Form.Control.Feedback>
          </Form.Group>


          <Button onClick={addResidentEve} type="submit" style={{ marginTop: "2rem" }}>
            Submit Form
          </Button>
        </Form>
      </div>
   

       </>
   )
}
export default EventReg