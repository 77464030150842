import Header from "./Header";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';

function UpdateVisitors(props) {
  const [visitor, setVisitors] = useState({ dl_number: '',driver_name: '',email: '',mobile_number: '', date_of_birth: '', guests: '' , car_plate_number: '' , entry_number: '' });
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    async function fetchData() {
      if (id) {
        let result = await fetch(`http://localhost:8000/api/getVisitor/${id}`);
        result = await result.json();
        setVisitors(result);
      }
    }
    fetchData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(`http://localhost:8000/api/updateVisitor/`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(visitor)
    });
    const data = await response.json();
    console.log(data); // for debugging purposes
    navigate('/ListVisitors');
  };

  return (
    <div>
      <Header />
      <h1>Update Visitors</h1>
      <form onSubmit={handleSubmit}>
      <label>
          DL Number:
          <input type="text" value={visitor.dl_number} onChange={(e) => setVisitors({ ...visitor, dl_number: e.target.value })} />
        </label>
        <br />
        <label>
        Driver Name:
          <input type="text" value={visitor.driver_name} onChange={(e) => setVisitors({ ...visitor, driver_name: e.target.value })} />
        </label>
        <br />
        <label>
          Email:
          <input type="email" value={visitor.email} onChange={(e) => setVisitors({ ...visitor, email: e.target.value })} />
        </label>
        <br />
        <label>
          Mobile Number:
          <input type="tel" value={visitor.mobile_number} onChange={(e) => setVisitors({ ...visitor, mobile_number: e.target.value })} />
        </label>
        <br />
        <label>
          Date Of Birth:
          <input type="text" value={visitor.date_of_birth} onChange={(e) => setVisitors({ ...visitor, date_of_birth: e.target.value })} />
        </label>
        <br />
        

        <label>
          Guests:
          <input type="text" value={visitor.guests} onChange={(e) => setVisitors({ ...visitor, guests: e.target.value })} />
        </label>
        <br />
        <label>
          Car Plate No:
          <input type="text" value={visitor.car_plate_number} onChange={(e) => setVisitors({ ...visitor, car_plate_number: e.target.value })} />
        </label>
        <br />
        <label>
          Entry No:
          <input type="text" value={visitor.entry_number} onChange={(e) => setVisitors({ ...visitor, entry_number: e.target.value })} />
        </label>
        <br />
        <button type="submit">Update Visitors</button>
      </form>
    </div>
  );
}

export default UpdateVisitors;

