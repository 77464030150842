import Header from "./Header";
import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
// import { Link } from "react-router-dom";
function ListGardenTimings(){



        const [data1,setData1]=useState([]);
        useEffect( ()=>{
            
            fetchData1();
        },[])
        console.warn("result",data1)
    
        async function fetchData1(){
            let result = await fetch("http://localhost:8000/api/listGardenTimings");
            result = await result.json();
            setData1(result)
            }
    return (

        <div>
            <Header/>

                <h4>Garden Activity Timings</h4>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Day</th>
                            <th>Start Timings</th>
                            <th>End Timings</th>
                            <th>Operations</th>
                           
                            {/* <th>Operations</th> */}

                        </tr>
                    </thead>
                    <tbody>
                        {
                            data1.map((gar)=>
                                <tr>
                                    <th>{gar.id}</th>
                                    <th>{gar.day}</th>
                                    <th>{gar.start_timing}</th>
                                    <th>{gar.end_timing}</th>
                                    <Link to={"/UpdateGardenTimings/"+gar.id}>
                                    <span  className="update">Update</span>
                                    </Link>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>

            </div>
      
    )


    
}
export default ListGardenTimings