import Header from "./Header";
import { useState } from "react";

function AddShiftTimings(){

    const [name,setName]=useState("");
    const [emp_id,setEmp]=useState("");
    const [start_timing,setStart]=useState("");
    const [end_timing,setEnd]=useState("");
    const [area,setArea]=useState("");

    async  function addSecurity(){
        console.warn(name,emp_id,start_timing,end_timing,area)
        const formData = new FormData();
        formData.append('name',name);
        formData.append('emp_id',emp_id);
        formData.append('start_timing',start_timing);
        formData.append('end_timing',end_timing);
        formData.append('area',area);
        let result = await fetch("http://localhost:8000/api/addShifts",{
        method:'POST',
        body:formData
        
    });
    alert("Data has been saved")

    }

    return(

        <div>
            <Header/>
            <h1>Add Security Guard</h1>
            <div className="col-sm-6 offset-sm-3">
                <input type="text" value={name} className="form-control" onChange={(e)=>setName(e.target.value)} placeholder="Name"/> <br/>
                <input type="text" value={emp_id} className="form-control" onChange={(e)=>setEmp(e.target.value)} placeholder="Employee Id"/> <br/>
                <input type="time" value={start_timing} className="form-control" onChange={(e)=>setStart(e.target.value)} placeholder="Start Time"/> <br/>
                <input type="time" value={end_timing} className="form-control" onChange={(e)=>setEnd(e.target.value)} placeholder="End Time"/> <br/>
                <input type="text" value={area} className="form-control" onChange={(e)=>setArea(e.target.value)} placeholder="Area"/> <br/>
                
                <button onClick={addSecurity} className="btn btn-primary">Add Manager</button>
            </div>

        </div>

    )
  
}
export default AddShiftTimings