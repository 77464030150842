import Header from './Header';
import { Card, Row, Col } from 'react-bootstrap';
function About(){
    return(
      <div>
      <Header />
      <div className="about-section">
        <h1>About Us</h1>
        <p>Ground floor apartment for 5 people, recently renovated, with covered terrace and electrical backup. All the luxury and comfort and steps from Playa Guacuco. Two huge pools, restaurant, bodegón, tennis court, beautiful spaces for walking and cycling.
          We have WiFi, electric shutters, 2 TVs with Netflix, Star+, Disney+, HBO MAX, AppleTV and Prime Video, surfboards and bodyboards, bicycles, barbecue and everything for an unforgettable vacation.
          Space
          The distribution of the beds is as follows:
          Main room, a double bed.
          Room 2, two drawer bunk beds (4 single beds).
          Two full bathrooms.
          Super equipped kitchen, living room and dining room.
          A covered terrace with armchairs and tables, ideal for sharing breakfasts, coffee afternoons and game nights with friends.</p>
      </div>
      <h2 style={{ textAlign: 'center' }}>Our Designs</h2>
      <Row className="justify-content-center" >
        <Col md={4}>
          <Card style={{ width: '30rem' }}>
            <Card.Img variant="top" src={require('../images/1.png')} />
            <Card.Body>
              <Card.Title>Town Houses</Card.Title>
              <Card.Text>
                Live in a relaxing retreat at Bexley at Left Bank, apartments and townhouses in Fort Worth inspired by the organic beauty of the neighboring River. Find unparalleled, high-end smart homes with top-of-market finishes and clean, modern lines. Relax at the rooftop infinity pool and penthouse sky deck. Cultivate the ultimate urban lifestyle on the smart home featured home edge of this city’s exhilarating renaissance.
              </Card.Text>
              
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card style={{ width: '30rem' }}>
            <Card.Img variant="top" src={require('../images/2.png')} />
            <Card.Body>
              <Card.Title>Cabins</Card.Title>
              <Card.Text>
                The cabin was built by a family who will have been relatively well off, judging by the size of it. It average size of a cabin during that time period was 12×12 feet, however, Nothnagle Cabin is 16×22 feet. On the sidewall, two logs are removable, so that they could be removed during summer to cool the cabin down.
              </Card.Text>
              
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card style={{ width: '30rem' }}>
            <Card.Img variant="top" src={require('../images/3.png')} />
            <Card.Body>
              <Card.Title>Studios</Card.Title>
              <Card.Text>
                The ultimate location, luxurious, designer interiors, and opulent amenities to complement your vibrant lifestyle. Experience the city’s most exclusive live-work-play location, complete with beautiful patios, a state-of-the-art fitness center, and a rooftop plunge plunge pool with gorgeous views.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        </Row>
        </div>
    )
}
export default About;