import Header from "./Header";
import React,{useState,useEffect} from "react";
import { Table } from "react-bootstrap";
// import { Link } from "react-router-dom";
function ListTimings(){

    const [data,setData]=useState([]);
    useEffect( ()=>{
        
        fetchData();
    },[])
    console.warn("result",data)

    async function fetchData(){
        let result = await fetch("http://localhost:8000/api/listPoolTimings");
        result = await result.json();
        setData(result)
        }

        const [data1,setData1]=useState([]);
        useEffect( ()=>{
            
            fetchData1();
        },[])
        console.warn("result",data1)
    
        async function fetchData1(){
            let result = await fetch("http://localhost:8000/api/listGardenTimings");
            result = await result.json();
            setData1(result)
            }
    return (

        <div>
            <Header/>
            <h4>Pool Activity Timings</h4>
            <div className="col-sm-8 offset-sm-2">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Day</th>
                            <th>Start Timings</th>
                            <th>End Timings</th>
                           
                            {/* <th>Operations</th> */}

                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((pool)=>
                                <tr>
                                    <th>{pool.id}</th>
                                    <th>{pool.day}</th>
                                    <th>{pool.start_timing}</th>
                                    <th>{pool.end_timing}</th>

                                </tr>
                            )
                        }
                    </tbody>
                </Table> <br/> <br/> <br/>
                <h4>Garden Activity Timings</h4>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Day</th>
                            <th>Start Timings</th>
                            <th>End Timings</th>
                           
                            {/* <th>Operations</th> */}

                        </tr>
                    </thead>
                    <tbody>
                        {
                            data1.map((gar)=>
                                <tr>
                                    <th>{gar.id}</th>
                                    <th>{gar.day}</th>
                                    <th>{gar.start_timing}</th>
                                    <th>{gar.end_timing}</th>

                                </tr>
                            )
                        }
                    </tbody>
                </Table>

            </div>
        </div>

    )


    
}
export default ListTimings