import React,{ useState,useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import Header from "./Header";
import axios from "axios";
function Register(){
    useEffect(()=>{
        if(localStorage.getItem('user-info')){
            Navigate("/Login")

        }
    },[])

    const [name,setName]=useState("")
    const [password,setPassword]=useState("")
    const [email,setEmail]=useState("")
    const [userType,setUser]=useState("")
    const [mobile,setMobile]=useState("")
    const Navigate= useNavigate();

    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [userTypeError, setUserTypeError] = useState('');

    function validate() {
        let nameError = '';
        let emailError = '';
        let mobileError = '';
        let passwordError = '';
        let userTypeError = '';

        if (!name) {
            nameError = 'Name cannot be blank';
        }

        if (!email.includes('@') || !email.includes('.')) {
            emailError = 'Invalid email';
        }

        if (!mobile || isNaN(mobile) || mobile.length !== 10) {
            mobileError = 'Invalid mobile number';
        }

        if (password.length < 8) {
            passwordError = 'Password must be at least 8 characters long';
        }

        if (!userType) {
            userTypeError = 'User Type cannot be blank';
        }

        if (nameError || emailError || mobileError || passwordError || userTypeError) {
            setNameError(nameError);
            setEmailError(emailError);
            setMobileError(mobileError);
            setPasswordError(passwordError);
            setUserTypeError(userTypeError);
            return false;
        }
        return true;
    }

  async  function signUp()

    {if(validate()){
        let item={name,password,email,userType,mobile}
        console.warn(item);
        let result = await fetch  ("http://localhost:8000/api/register",{
        method:'POST',
        body:JSON.stringify(item),
        headers:{

            "Content-Type":'application/json',
            "Accept":'application/json'

        }


        })
        .then(response => {
            if (response.ok) {
                alert('Registration successful!'); // Display a success message
            } else {
                throw new Error('Registration failed.'); // Throw an error if the response is not ok
            }
        })
        .catch(error => {
            console.error(error); // Log the error to the console
            alert('Registration failed. Please try again.'); // Display an error message
        });

        result = await result.json()
        localStorage.setItem("user-info",JSON.stringify(result))
        // Navigate("/Login")
        axios.post('http://localhost:8000/api/emailVerify', {
            email: email
        })
        .then(response => {
            console.log(response.data);
        })
        .catch(error => {
            console.error(error);
        });
        
    }
}
    async function login(){
        Navigate("/Login")
    }
    
    return(
        <>
    <Header/>
        <div className="col-sm-6 offset-sm-3">
            <h1>
                Register Page </h1>
                <input type ="text" value={name} onChange={(e)=>setName(e.target.value)} className="form-control" placeholder="name"/>
                <div style={{ color: 'red' }}>{nameError}</div>
                <br/>
                <input type ="password" value={password} onChange={(e)=>setPassword(e.target.value)} className="form-control" placeholder="password"/>
                <div style={{ color: 'red' }}>{passwordError}</div>
                <br/>
                <input type ="text" value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control" placeholder="email"/>
                <div style={{ color: 'red' }}>{emailError}</div>
                <br/>
                <input type ="text" value={userType} onChange={(e)=>setUser(e.target.value)} className="form-control" placeholder="resident/visitor"/>
                <div style={{ color: 'red' }}>{userTypeError}</div>
                <br/>
                <input type ="text" value={mobile} onChange={(e)=>setMobile(e.target.value)} className="form-control" placeholder="mobile"/>
                <div style={{ color: 'red' }}>{mobileError}</div>
                <br/>
                <button onClick={signUp} className="btn btn-primary">Sign Up</button>
                <br/>
                <h4>Already Registered?</h4>
                <button onClick={login} className="btn btn-primary">Login</button>
           

        </div>
        </>
    )
}
export default Register;