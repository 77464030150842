import Header1 from "./Header1";
import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";



function BuildingManager(){

    return(
        <div>
            <Header1/>
            <h1>Building Manager</h1>
            <div className="col-sm-6 offset-sm-3">

                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/addmanager.jpg')}/>
                    <Card.Body>
                        <Card.Title>Add Managers</Card.Title>
                        <Card.Text>
                           Add Managers to the Buildings such as Studios, Pool, Townhalls , Cabins and Garden
                        </Card.Text>
                        <Button href="/AddManagers" variant="primary">Add Manager</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/listmanager.jpg')}/>
                    <Card.Body>
                        <Card.Title>List Managers</Card.Title>
                        <Card.Text>
                           List of all Managers to the Buildings such as Studios, Pool, Townhalls , Cabins and Garden
                        </Card.Text>
                        <Button href="/ListManagers" variant="primary">List Manager</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/reports.png')}/>
                    <Card.Body>
                        <Card.Title>Generate Reports</Card.Title>
                        <Card.Text>
                        Generate Reports for all Managers to the Buildings such as Studios, Pool, Townhalls , Cabins and Garden
                        </Card.Text>
                        <Button href="/PiechartBuild" variant="primary">Generate Reports</Button>
                    </Card.Body>
                </Card>
                <Link id='chat-button' to="https://axa4926.uta.cloud/login.php" target="_blank">Chat with us</Link>


            </div>
        </div>
    )
  
}
export default BuildingManager;
