import { Form, Button, Card } from "react-bootstrap";
import Header from "./Header";

function Contact() {
  return (
    <Card className="mx-auto">
      <Card.Body>
        <Header />
        <h1>Contact Us</h1>
        <Form>
          <Form.Group controlId="formFirstName">
            <Form.Label>First Name:</Form.Label>
            <Form.Control type="text" placeholder="First Name" />
          </Form.Group>
          <Form.Group controlId="formLastName">
            <Form.Label>Last Name:</Form.Label>
            <Form.Control type="text" placeholder="Last Name" />
          </Form.Group>
          <Form.Group controlId="formPhone">
            <Form.Label>Phone:</Form.Label>
            <Form.Control type="text" placeholder="Phone" />
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label>Email:</Form.Label>
            <Form.Control type="email" placeholder="Email" />
          </Form.Group>
          <Form.Group controlId="formMoveInDate">
            <Form.Label>Move In Date:</Form.Label>
            <Form.Control type="date" />
          </Form.Group>
          <Form.Group controlId="formSelectFloorPlan">
            <Form.Label>Select Floor Plan:</Form.Label>
            <Form.Control as="select">
              <option value="" disabled selected>
                Select
              </option>
              <option value="std">Studios</option>
              <option value="cab">Cabins</option>
              <option value="tow">Townhalls</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formMessage">
            <Form.Label>Message:</Form.Label>
            <Form.Control as="textarea" rows={3} />
          </Form.Group>
          <Button variant="primary" type="submit">
            Send
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
}

export default Contact;
