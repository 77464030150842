import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Header from "./Header";

function EventReg() {


    const [name,setName]=useState("");
    const [Mob_no,setMobile]=useState("");
    const [email,setEmail]=useState("");
    const [flat_no,setFlat]=useState("");
    const [event_name,setEvent]=useState("");

    async  function addResidentEve(){
        console.warn(name,email,Mob_no,flat_no,event_name)
        const formData = new FormData();
        formData.append('name',name);
        formData.append('email',email);
        formData.append('Mob_no',Mob_no);
        formData.append('flat_no',flat_no);
        formData.append('event_name',event_name);
      
        let result = await fetch("http://localhost:8000/api/addResEve",{
        method:'POST',
        body:formData
        
    });
    alert("Data has been saved")

    }
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  return (
    <>
      <Header />
      <div className="d-flex flex-column align-items-center">
        <h1>Residents Event Registerations</h1>
        <Form
          style={{ width: "20rem", paddingTop: "2rem" }}
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <Form.Group>
            <Form.Label>Resident Name</Form.Label>
            <Form.Control
            value={name} className="form-control" onChange={(e)=>setName(e.target.value)}
              type="text"
              pattern="[A-Za-z ]+"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid resident name (letters and spaces only).
            </Form.Control.Feedback>
          </Form.Group>
          
          <Form.Group>
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
            value={Mob_no} className="form-control" onChange={(e)=>setMobile(e.target.value)}
              type="tel"
              pattern="[0-9]{10}"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid phone number.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control value={email} className="form-control" onChange={(e)=>setEmail(e.target.value)} type="email" required />
            <Form.Control.Feedback type="invalid">
              Please enter a valid email address.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Resident Flat No</Form.Label>
            <Form.Control
            value={flat_no} className="form-control" onChange={(e)=>setFlat(e.target.value)}
              type="text"
              pattern="[A-Za-z0-9]+"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid resident flat number (letters and spaces only).
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Event Name</Form.Label>
            <Form.Control
            value={event_name} className="form-control" onChange={(e)=>setEvent(e.target.value)}
              type="text"
              pattern="[A-Za-z ]+"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid Event name (letters and spaces only).
            </Form.Control.Feedback>
          </Form.Group>


          <Button onClick={addResidentEve} type="submit" style={{ marginTop: "2rem" }}>
            Submit Form
          </Button>
        </Form>
      </div>
   

       </>
   )
}
export default EventReg