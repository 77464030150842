import React from 'react';


function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <p>&copy; 2023 Team 8 Website. All rights reserved.</p>
        <p>
          Powered by <a href="https://reactjs.org/">React and Laravel</a>.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
