import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Form } from 'react-bootstrap';

function Payment() {
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [amount, setAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const cardElement = elements.getElement(CardElement);

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: name,
        email: email,
      },
    });

    if (!error) {
      const { id } = paymentMethod;

      try {
        const response = await fetch('http://localhost:8000/api/payment', {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify({ id, amount }),
        });

        if (response.ok) {
          console.log('Payment Successful!');
          // show success message or redirect to a success page
        }
      } catch (error) {
        console.error('Error:', error);
        // show error message
      }
    } else {
      console.error('Error:', error);
      // show error message
    }

    setIsLoading(false);
  };

  return (
    <div className="d-flex justify-content-center">
    <Card>
        <Card.Body>
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="name">
        <Form.Label>Name</Form.Label>
        <Form.Control type="text" placeholder="Enter name" value={name} onChange={(e) => setName(e.target.value)} required />
      </Form.Group>

      <Form.Group controlId="email">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} required />
      </Form.Group>

      <Form.Group controlId="amount">
        <Form.Label>Amount to pay</Form.Label>
        <Form.Control type="number" placeholder="Enter amount" value={amount} onChange={(e) => setAmount(e.target.value)} required />
      </Form.Group>

      <Form.Group controlId="card">
        <Form.Label>Card Details</Form.Label>
        <CardElement className="form-control" />
      </Form.Group>

      <Button variant="primary" type="submit" disabled={!stripe || isLoading} className="mt-3">
        {isLoading ? 'Processing...' : 'Pay'}
      </Button>
    </Form>
    </Card.Body>
    </Card>
    </div>
  );
}

export default Payment;
