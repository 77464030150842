import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Header from "./Header";

function PoolAppointment() {


    const [name,setName]=useState("");
    const [flat_no,setFlatno]=useState("");
    const [app_date,setAppno]=useState("");
    const [pool_type,setPooltype]=useState("");

    async  function addResident(){
        console.warn(name,flat_no,app_date,pool_type)
        const formData = new FormData();
        formData.append('name',name);
        formData.append('flat_no',flat_no);
        formData.append('app_date',app_date);
        formData.append('pool_type',pool_type);
        let result = await fetch("http://localhost:8000/api/addPoolAppointment",{
        method:'POST',
        body:formData
        
    });
    alert("Data has been saved")

    }
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  return (
    <>
      <Header />
      <div className="d-flex flex-column align-items-center">
        <h1>Pool Appointment Form</h1>
        <Form
          style={{ width: "20rem", paddingTop: "2rem" }}
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
            value={name} className="form-control" onChange={(e)=>setName(e.target.value)}
              type="text"
              pattern="[A-Za-z ]+"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid name (letters and spaces only).
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Flat No.</Form.Label>
            <Form.Control value={flat_no} className="form-control" onChange={(e)=>setFlatno(e.target.value)} type="email" required />
            <Form.Control.Feedback type="invalid">
              Please enter a valid flat no.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Appointment Date</Form.Label>
            <Form.Control
            value={app_date} className="form-control" onChange={(e)=>setAppno(e.target.value)}
              type="date"
              max={new Date().toISOString().split("T")[0]}
            //   pattern="\+?\d{1,3}[-\.\s]?\d{3}[-\.\s]?\d{3}[-\.\s]?\d{4}"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid appointment date.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Pool Type</Form.Label>
            <Form.Control
              as="select"
              value={pool_type}
              className="form-control"
              onChange={(e) => setPooltype(e.target.value)}
              required
            >
              <option value="">Select a pool type</option>
              <option value="Infinity">Infinity</option>
              <option value="Spa">Spa</option>
              <option value="Jacuzzi">Jacuzzi</option>
              <option value="Family Fun">Family Fun</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select a valid pool type.
            </Form.Control.Feedback>
          </Form.Group>

          <Button onClick={addResident} type="submit" style={{ marginTop: "2rem" }}>
            Submit Form
          </Button>
        </Form>
      </div>
   

       </>
   )
}
export default PoolAppointment