import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Header from "./Header";

function RegResidents() {


    const [driver_name,setName]=useState("");
    const [email,setEmail]=useState("");
    const [mobile_number,setMobile]=useState("");
    const [date_of_birth,setDate]=useState("");
    const [car_plate_number,setCar]=useState("");
    const [entry_number,setEntry]=useState("");
    const [dl_number,setDl]=useState("");

    async  function addResident(){
        console.warn(driver_name,email,mobile_number,date_of_birth,car_plate_number,entry_number,dl_number)
        const formData = new FormData();
        formData.append('driver_name',driver_name);
        formData.append('email',email);
        formData.append('mobile_number',mobile_number);
        formData.append('date_of_birth',date_of_birth);
        formData.append('car_plate_number',car_plate_number);
        formData.append('entry_number',entry_number);
        formData.append('dl_number',dl_number);
        let result = await fetch("http://localhost:8000/api/addVehicle",{
        method:'POST',
        body:formData
        
    });
    alert("Data has been saved")

    }
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  return (
    <>
      <Header />
      <div className="d-flex flex-column align-items-center">
        <h1>Residents Vehicle Registerations</h1>
        <Form
          style={{ width: "20rem", paddingTop: "2rem" }}
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <Form.Group>
            <Form.Label>Driver Name</Form.Label>
            <Form.Control
            value={driver_name} className="form-control" onChange={(e)=>setName(e.target.value)}
              type="text"
              pattern="[A-Za-z ]+"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid driver name (letters and spaces only).
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control value={email} className="form-control" onChange={(e)=>setEmail(e.target.value)} type="email" required />
            <Form.Control.Feedback type="invalid">
              Please enter a valid email address.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
            value={mobile_number} className="form-control" onChange={(e)=>setMobile(e.target.value)}
              type="tel"
              pattern="\+?\d{1,3}[-\.\s]?\d{3}[-\.\s]?\d{3}[-\.\s]?\d{4}"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid phone number.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Date Of Birth</Form.Label>
            <Form.Control
            value={date_of_birth} className="form-control" onChange={(e)=>setDate(e.target.value)}
              type="date"
              max={new Date().toISOString().split("T")[0]}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid date of birth.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Car Plate Number</Form.Label>
            <Form.Control
            value={car_plate_number} className="form-control" onChange={(e)=>setCar(e.target.value)}
              type="text"
              pattern="[A-Za-z]{1,3}-\d{1,4}"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid car plate number (e.g. ABC-1234).
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Entry Number</Form.Label>
            <Form.Control
            value={entry_number} className="form-control" onChange={(e)=>setEntry(e.target.value)}
              type="number"
              min={1}
              step={1}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid entry number (a positive integer).
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>DL number</Form.Label>
            <Form.Control
            value={dl_number} className="form-control" onChange={(e)=>setDl(e.target.value)}
              type="text"
              pattern="[A-Za-z]{2}\d{13}"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid driver's license number.
            </Form.Control.Feedback>
          </Form.Group>

          <Button onClick={addResident} type="submit" style={{ marginTop: "2rem" }}>
            Submit Form
          </Button>
        </Form>
      </div>
   

       </>
   )
}
export default RegResidents