import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import Card from 'react-bootstrap/Card';
function PiechartPool() {
  const [poolData, setPoolData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch("http://localhost:8000/api/listPoolAppointment");
      const data = await res.json();
      setPoolData(data);
    };
    fetchData();
  }, []);

  let infinityPoolCount = 0;
  let familyFunPoolCount = 0;
  let spaPoolCount = 0;
  let jacuzziCount = 0;
  for (let i = 0; i < poolData.length; i++) {
    if (poolData[i].pool_type === "infinity") {
      infinityPoolCount += 1;
    } else if (poolData[i].pool_type === "spa") {
      spaPoolCount += 1;
    }
    else if (poolData[i].pool_type === "jacuzzi") {
      jacuzziCount += 1;
      }
      else if (poolData[i].pool_type === "family fun") {
        familyFunPoolCount += 1;
      }
  }

  const chartData = [infinityPoolCount,familyFunPoolCount,spaPoolCount,jacuzziCount];
  console.log(chartData);

  const chartLabels = ["Infinity Pool", "Family Fun Pool", "Spa Pool","Jacuzzi Pool"];
  console.log(chartLabels);
  const chartColors = ["#E91E63", "#9C27B0", "#673AB7", "#3F51B5", "#2196F3", "#00BCD4"];
  return (
    <div className="pie-chart-container">
      <Card className="piesecuritycard">
        <Card.Body>
          <h1>Pool Apoointments Dashboard</h1>
          <Chart
            options={{
              title: {
                text: "",
              },
              labels: chartLabels,
              dataLabels: {
                enabled: true,
                formatter: function (val, opts) {
                  return opts.w.config.series[opts.seriesIndex];
                },
                background: {
                  enabled: true,
                  foreColor: "#fff",
                  borderRadius: 3,
                  padding: 5,
                  opacity: 0.9,
                  borderWidth: 1,
                  borderColor: "#fff",
                },
              },
              colors: chartColors,
            }}
            series={chartData}
            type="pie"
            width={500}
          />
          <Card.Text>
            This is the Generated report of Pool Appointments with number of residents and visitors in the complex
          </Card.Text>
          {/* <Button variant="primary">Go somewhere</Button> */}
        </Card.Body>
        <Card.Footer className="text-muted">Few Minutes Ago</Card.Footer>
      </Card>
      
    </div>

    
  );
}

export default PiechartPool;