import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

function PiechartSecurity() {
  const [managerData, setManagerData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch("http://localhost:8000/api/listShifts");
      const data = await res.json();
      setManagerData(data);
    };
    fetchData();
  }, []);

  let buildingEntranceCount = 0;
  let poolCount = 0;
  let EntranceCount = 0;
  let GardenCount = 0;
  for (let i = 0; i < managerData.length; i++) {
    if (managerData[i].area === "Building") {
      buildingEntranceCount += 1;
    } else if (managerData[i].area === "Pool") {
      poolCount += 1;
    }
    else if (managerData[i].area === "Entrance") {
        EntranceCount += 1;
      }
      else if (managerData[i].area === "Garden") {
        GardenCount += 1;
      }
  }

  const chartData = [buildingEntranceCount, poolCount,EntranceCount,GardenCount];
  console.log(chartData);

  const chartLabels = ["Building", "Pool", "Entrance", "Garden"];
  console.log(chartLabels);

  return (
    <div className="pie-chart-container">
                <Card className="piesecuritycard">
      
      <Card.Body>
        <h1>Security Dashboard</h1>
      <Chart
        options={{
          title: {
            text: "",
          },
          labels: chartLabels,
          dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
              return opts.w.config.series[opts.seriesIndex];
            },
            background: {
              enabled: true,
              foreColor: "#fff",
              borderRadius: 3,
              padding: 5,
              opacity: 0.9,
              borderWidth: 1,
              borderColor: "#fff",
            },
          },
        }}
        series={chartData}
        type="pie"
        width={500}
      />

        <Card.Text>
         This is the Generated report of Security Managers in the complex
        </Card.Text>
        {/* <Button variant="primary">Go somewhere</Button> */}
      </Card.Body>
      <Card.Footer className="text-muted">Few Minutes Ago</Card.Footer>
    </Card>
      
    </div>

    
  );
}

export default PiechartSecurity;
