import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Navbar,Container,Nav,NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

function AdminManager() {

    const Navigate= useNavigate();
    let user = JSON.parse(localStorage.getItem("user-info"))
   function logOut(){
    localStorage.clear()
    Navigate("/Login")
   }

  //  function handleChatClick() {
  //   Navigate('https://axa4926.uta.cloud/users.php')
    
  // }
  
  return (
    <div>
          <Navbar bg="dark" variant="dark">
              <Container>
                  <Navbar.Brand href="/">Terrazas</Navbar.Brand>
                  <Nav className="mg-auto">
                      <Nav.Link href="/BuildingManager">Building Manager</Nav.Link>
                      <Nav.Link href="/ResidentManager">Manage Resident</Nav.Link>
                      <Nav.Link href="/VisitorManager">Manage Visitor</Nav.Link>
                      <Nav.Link href="/PoolManager">Pool Manager</Nav.Link>
                      <Nav.Link href="/VisitorManager">Visitor Manager</Nav.Link>
                      <Nav.Link href="/GardenManager">Garden Manager</Nav.Link>
                      <Nav.Link href="/SecurityManager">Security Manager</Nav.Link>
                      {localStorage.getItem('user-info') ?
                          <Nav>
                              <NavDropdown title={user && user.name}>
                                  <NavDropdown.Item onClick={logOut}>
                                      Log Out
                                  </NavDropdown.Item>
                              </NavDropdown>
                          </Nav>
                          : null}
                  </Nav>
              </Container>
          </Navbar>
          <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/building.jpg')}/>
                    <Card.Body>
                        <Card.Title>Building Dashboard</Card.Title>
                        <Card.Text>
                        Generate Reports for all Managers to the Buildings such as Studios, Pool, Townhalls , Cabins and Garden
                        </Card.Text>
                        <Button href="/PiechartBuild" variant="primary">Generate Reports</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/addsecurity.jpg')}/>
                    <Card.Body>
                        <Card.Title>Security Dashboard</Card.Title>
                        <Card.Text>
                        Generate Reports for all Securities to the Buildings, Pools , Gardens and Entrance. 
                        </Card.Text>
                        <Button href="/PiechartSecurity" variant="primary">Generate Reports</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/garden.jpg')}/>
                    <Card.Body>
                        <Card.Title>Garden Dashboard</Card.Title>
                        <Card.Text>
                        Generate Reports for all Residents and Visitors in the Gardens                      </Card.Text>
                        <Button href="/PieChartGarden" variant="primary">Generate Reports</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/pool.jpg')}/>
                    <Card.Body>
                        <Card.Title>Pool Dashboard</Card.Title>
                        <Card.Text>
                        Generate Reports for all Residents and Visitors in the Pools                        </Card.Text>
                        <Button href="/PieChartPool" variant="primary">Generate Reports</Button>
                    </Card.Body>
                </Card>
                <Link id='chat-button' to="https://axa4926.uta.cloud/login.php" target="_blank">Chat with us</Link>

    </div>
  )
}

export default AdminManager
