import Header from "./Header";
import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";


function ResidentManager(){


      

    

    return(

        <div>
            <Header/>
            <h1>Visitor</h1>
            <div className="col-sm-6 offset-sm-3"><br/> <br/> <br/>
            {/* <Link to={"/AddResidents"} className="btn btn-primary">Add Residents</Link> */}
            <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/listvisitor.png')}/>
                    <Card.Body>
                        <Card.Title>List of Visitors</Card.Title>
                        <Card.Text>
                           List of all Visitors to the Buildings such as Studios, Pool, Townhalls , Cabins and Garden
                        </Card.Text>
                        <Button href="/ListVisitors" variant="primary">List Visitors</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/vehicleregisteration.jpg')}/>
                    <Card.Body>
                        <Card.Title>Register Visitor Entry</Card.Title>
                        <Card.Text>
                           Register all the Visitors in the Complex. 
                        </Card.Text>
                        <Button href="/RegResidents" variant="primary">Register Visitors Vehicle</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/navigation.png')}/>
                    <Card.Body>
                        <Card.Title>Access the Navigation Map</Card.Title>
                        <Card.Text>
                           Visitors can Navigate through the Complex using the Maps . 
                        </Card.Text>
                        <Button href="/Directions" variant="primary">Navigate</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/timings.jpg')}/>
                    <Card.Body>
                        <Card.Title>Access All the Timings of activities</Card.Title>
                        <Card.Text>
                          Access all the timings for the activities like pool and garden timings. 
                        </Card.Text>
                        <Button href="/ListTimings" variant="primary">Access times</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/eventreg.jpg')}/>
                    <Card.Body>
                        <Card.Title>Known Event Registerations</Card.Title>
                        <Card.Text>
                         Register to the Envents whic are already known which are happening in the complex.
                        </Card.Text>
                        <Button href="/EventReg" variant="primary">Event Registerations</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/adv.jpg')}/>
                    <Card.Body>
                        <Card.Title>Event Advertisements</Card.Title>
                        <Card.Text>
                        Events which are recently advertised for the residents & visitors will be able to register here.
                        </Card.Text>
                        <Button href="/ListAdvertisements" variant="primary">Event Advertisement</Button>
                    </Card.Body>
                </Card>

                <Link id='chat-button' to="https://axa4926.uta.cloud/login.php" target="_blank">Chat with us</Link>

            
            </div>

        </div>

    )
  
}
export default ResidentManager