import Header from "./Header";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';

function UpdateShiftTimings(props) {
  const [security, setSecurity] = useState({ name: '',emp_id: '', start_timing: '', end_timing: '',area: '' });
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    async function fetchData() {
      if (id) {
        let result = await fetch(`http://localhost:8000/api/getShifts/${id}`);
        result = await result.json();
        setSecurity(result);
      }
    }
    fetchData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(`http://localhost:8000/api/updateShifts/`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(security)
    });
    const data = await response.json();
    console.log(data); // for debugging purposes
    navigate('/ListShiftTimings');
  };

  return (
    <div>
      <Header />
      <h1>Update Security Shift Timings</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input type="text" value={security.name} onChange={(e) => setSecurity({ ...security, name: e.target.value })} />
        </label>
        <br />
        <label>
          Employee Id:
          <input type="text" value={security.emp_id} onChange={(e) => setSecurity({ ...security, emp_id: e.target.value })} />
        </label>
        <br />
        <label>
          Start Time:
          <input type="text" value={security.start_timing} onChange={(e) => setSecurity({ ...security, start_timing: e.target.value })} />
        </label>
        <br />

        <label>
          End Time:
          <input type="text" value={security.end_timing} onChange={(e) => setSecurity({ ...security, end_timing: e.target.value })} />
        </label>
        <br />
        <label>
          Area:
          <input type="text" value={security.area} onChange={(e) => setSecurity({ ...security, area: e.target.value })} />
        </label>
        <br />
        <button type="submit">Update Timings</button>
      </form>
    </div>
  );
}

export default UpdateShiftTimings

