import Header from "./Header";
import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import {  useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
function ResidentManager(){
    const navigate = useNavigate();
    function handleChatClick() {
        navigate("/Chat");
        // alert("Chat feature coming soon!");
      }
    return(
        <div>
            <Header/>
            <h1>Resident </h1>
            <div className="col-sm-6 offset-sm-3"><br/> <br/> <br/>
            {/* <Link to={"/AddResidents"} className="btn btn-primary">Add Residents</Link> */}
            <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/listresidents.jpg')}/>
                    <Card.Body>
                        <Card.Title>List of Residents</Card.Title>
                        <Card.Text>
                           List of all Residents to the Buildings such as Studios, Pool, Townhalls , Cabins and Garden
                        </Card.Text>
                        <Button href="/ListResidents" variant="primary">List Residents</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/vehicleregisteration.jpg')}/>
                    <Card.Body>
                        <Card.Title>Register Residents Vehicle</Card.Title>
                        <Card.Text>
                           Register all the vehicle registerations of the Residents in the Complex. 
                        </Card.Text>
                        <Button href="/RegResidents" variant="primary">Register Residents Vehicle</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/timings.jpg')}/>
                    <Card.Body>
                        <Card.Title>Access All the Timings of activities</Card.Title>
                        <Card.Text>
                          Access all the timings for the activities like pool and garden timings. 
                        </Card.Text>
                        <Button href="/ListTimings" variant="primary">Access timings</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/join.png')}/>
                    <Card.Body>
                        <Card.Title>Join / Leave Menemberships</Card.Title>
                        <Card.Text>
                         Join Membershipsfor Pool and Garden for the complex.
                        </Card.Text>
                        <Button href="/Join" variant="primary">Join</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/eventreg.jpg')}/>
                    <Card.Body>
                        <Card.Title>Known Event Registerations</Card.Title>
                        <Card.Text>
                         Register to the Envents whic are already known which are happening in the complex.
                        </Card.Text>
                        <Button href="/EventReg" variant="primary">Event Registerations</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/adv.jpg')}/>
                    <Card.Body>
                        <Card.Title>Event Advertisements</Card.Title>
                        <Card.Text>
                        Events to be held in the complex for the residents.
                        </Card.Text>
                        <Button href="/ListAdvertisements" variant="primary">Event Advertisement</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/pay.jpg')}/>
                    <Card.Body>
                        <Card.Title>Pay Rent / Maintenance</Card.Title>
                        <Card.Text>
                        Residents will be able to pay there Rent and Maintainence bills of the complex.
                        </Card.Text>
                        <Button href="/Pay" variant="primary">Payment</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/pool.jpg')}/>
                    <Card.Body>
                        <Card.Title>Pool Appointments</Card.Title>
                        <Card.Text>
                        Make an Appointment for Pool.
                        </Card.Text>
                        <Button href="/PoolAppointment" variant="primary">Pool Appointment</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/garden.jpg')}/>
                    <Card.Body>
                        <Card.Title>Garden Appointments</Card.Title>
                        <Card.Text>
                        Make an Appointment for Garden.
                        </Card.Text>
                        <Button href="/GardenAppointment" variant="primary">Garden Appointment</Button>
                    </Card.Body>
                </Card>
                <Link id='chat-button' to="https://axa4926.uta.cloud/login.php" target="_blank">Chat with us</Link>

            </div>
        </div>
    )
}
export default ResidentManager