import React, { useState } from "react";
import { Form, Button, Container, Row, Col, Alert, Card } from "react-bootstrap";
import Header from "../pages/Header";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);

  async function handleForgotPassword(e) {
    e.preventDefault();
    console.log(email);
    const item = { email };
    console.warn(item);
    const response = await fetch("http://localhost:8000/api/forgotpass", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(item),
    });

    if (response.ok) {
      setShowSuccess(true);
    } else {
      setShowSuccess(false);
    }
  }

  return (
    <>
      <Header />
      <Container>
        <Row className="justify-content-center align-items-center vh-100">
          <Col md={6}>
            <Card>
              <Card.Header>Forgot Password</Card.Header>
              <Card.Body>
                <Form onSubmit={handleForgotPassword}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Form.Text className="text-muted">
                      We'll send a reset link to your email.
                    </Form.Text>
                  </Form.Group>

                  {showSuccess && (
                    <Alert variant="success" className="my-3">
                      Password reset link sent to {email}.
                    </Alert>
                  )}

                  <Button
                    variant="primary"
                    type="submit"
                    size="sm"
                    className="my-3"
                  >
                    Submit
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ForgotPassword;
