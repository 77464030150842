import React from 'react';
import PropTypes from 'prop-types';

const Directions = ({ directions }) => {
  return (
    <div>
      <h2>Directions</h2>
      <iframe src="https://www.google.com/maps/d/embed?mid=172d_uSZBGmx6f434RED6wt3XHWmCEMI&ehbc=2E312F" width="640" height="480"></iframe>
    </div>
  );
};

Directions.propTypes = {
  directions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Directions;
