import Header from './Header';
import { Card, Row, Col } from 'react-bootstrap';

function Services(){

    return(
        <div>
          <Header/>

            <h2><br/>
              Services Provided in the Complex
            </h2><br/><br/><br/>
            <Row className="justify-content-center" >
            <Col md={4}>
          <Card style={{ width: '30rem' }}>
            <Card.Img variant="top" src={require('../images/pool.jpg')} />
            <Card.Body>
              <Card.Title>Swimming Pool</Card.Title>
              <Card.Text>
              To provide a place for people to swim and play in the water. Swimming is a popular form of exercise and can improve cardiovascular health, build endurance, and strengthen muscles.              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card style={{ width: '30rem' }}>
            <Card.Img variant="top" src={require('../images/tennis.jpeg')} />
            <Card.Body>
              <Card.Title>Tennis Court</Card.Title>
              <Card.Text>
              The purpose of a tennis court is to provide a designated space for playing the sport of tennis. Tennis is a popular racquet sport played between two or four players, and it requires a specific type of court with specific dimensions, lines, and surfaces.            </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card style={{ width: '30rem' }}>
            <Card.Img variant="top" src={require('../images/garden.jpg')} />
            <Card.Body>
              <Card.Title>Garden</Card.Title>
              <Card.Text>
              gardens can serve many purposes, including providing beauty, food, environmental benefits, education, and therapy.            </Card.Text>
            </Card.Body>
          </Card>
        </Col>

          </Row>

        </div>
    )
}
export default Services;