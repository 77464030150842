import Header from "./Header";
import React,{useState,useEffect} from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
function ListGardens(){

    const [data,setData]=useState([]);
    useEffect( ()=>{
        
        fetchData();
    },[])
    console.warn("result",data)
   async function deleteOperation(id){
      let result= await fetch("http://localhost:8000/api/deleteGarden/"+id,{
            method:'DELETE'
        });
        result= await result.json();
        console.warn(result)
        fetchData();
    }
    async function fetchData(){
        let result = await fetch("http://localhost:8000/api/listGarden");
        result = await result.json();
        setData(result)
        }
    return (

        <div>
            <Header/>
            <h4>List of Garden Managers</h4>
            <div className="col-sm-8 offset-sm-2">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>Operations</th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((item)=>
                                <tr>
                                    <th>{item.id}</th>
                                    <th>{item.name}</th>
                                    <th>{item.email}</th>
                                    <th>{item.mobile}</th>
                                    <th><span onClick={()=>deleteOperation(item.id)} className="delete">Delete</span>
                                    <Link to={"/UpdateGardens/"+item.id}>
                                    <span  className="update">Update</span>
                                    </Link>
                                    </th>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>

            </div>
        </div>

    )


    
}
export default ListGardens