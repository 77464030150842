import React, { useState } from 'react';


function MembershipButton({ type, onJoin, onLeave, isJoined }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    if (isJoined) {
      await onLeave(type);
    } else {
      await onJoin(type);
    }
    setIsLoading(false);
  };

  return (
    <button className={isJoined ? 'btn-leave' : 'btn-join'} onClick={handleClick} disabled={isLoading}>
      {isLoading ? 'Loading...' : isJoined ? `Leave ${type} Membership` : `Join ${type} Membership`}
    </button>
  );
}

function Join() {
  const [joinedMemberships, setJoinedMemberships] = useState([]);
  const [joinSuccess, setJoinSuccess] = useState('');

  const joinMembership = async (membershipType) => {
    const response = await fetch('/joinMembership', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ type: membershipType })
    });
    if (response.ok) {
      setJoinedMemberships([...joinedMemberships, membershipType]);
      setJoinSuccess(`You have successfully joined the ${membershipType} membership.`);
    }
  };

  const leaveMembership = async (membershipType) => {
    const response = await fetch('/leaveMembership', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ type: membershipType })
    });
    if (response.ok) {
      setJoinedMemberships(joinedMemberships.filter((type) => type !== membershipType));
    }
  };

  return (
    <div className="join-container">
      <MembershipButton
        type="pool"
        onJoin={joinMembership}
        onLeave={leaveMembership}
        isJoined={joinedMemberships.includes('pool')}
      />
      <MembershipButton
        type="garden"
        onJoin={joinMembership}
        onLeave={leaveMembership}
        isJoined={joinedMemberships.includes('garden')}
      />
      {joinSuccess && <p className="join-success">{joinSuccess}</p>}
    </div>
  );
}

export default Join;

