import Header from "./Header";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';

function UpdatePoolTimings(props) {
  const [pool, setPool] = useState({ day: '', start_timing: '', end_timing: '' });
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    async function fetchData() {
      if (id) {
        let result = await fetch(`http://localhost:8000/api/getPoolTimings/${id}`);
        result = await result.json();
        setPool(result);
      }
    }
    fetchData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(`http://localhost:8000/api/updatePoolTimings/`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(pool)
    });
    const data = await response.json();
    console.log(data); // for debugging purposes
    navigate('/ListPoolTimings');
  };

  return (
    <div>
      <Header />
      <h1>Update Pool Timings</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input type="text" value={pool.day} onChange={(e) => setPool({ ...pool, day: e.target.value })} />
        </label>
        <br />
        <label>
          Start Time:
          <input type="text" value={pool.start_timing} onChange={(e) => setPool({ ...pool, start_timing: e.target.value })} />
        </label>
        <br />

        <label>
          End Time:
          <input type="text" value={pool.end_timing} onChange={(e) => setPool({ ...pool, end_timing: e.target.value })} />
        </label>
        <br />
        <button type="submit">Update Timings</button>
      </form>
    </div>
  );
}

export default UpdatePoolTimings

