import Header from "./Header";
import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
// import { Link } from "react-router-dom";
function ListPoolTimings(){

    const [data,setData]=useState([]);
    useEffect( ()=>{
        
        fetchData();
    },[])
    console.warn("result",data)

    async function fetchData(){
        let result = await fetch("http://localhost:8000/api/listPoolTimings");
        result = await result.json();
        setData(result)
        }


    return (

        <div>
            <Header/>
            <h4>Pool Activity Timings</h4>
            <div className="col-sm-8 offset-sm-2">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Day</th>
                            <th>Start Timings</th>
                            <th>End Timings</th>
                           
                            <th>Operations</th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((pool)=>
                                <tr>
                                    <th>{pool.id}</th>
                                    <th>{pool.day}</th>
                                    <th>{pool.start_timing}</th>
                                    <th>{pool.end_timing}</th>
                                    <Link to={"/UpdatePoolTimings/"+pool.id}>
                                    <span  className="update">Update</span>
                                    </Link>
                                </tr>
                            )
                        }
                    </tbody>
                </Table> <br/> <br/> <br/>
 

            </div>
        </div>

    )


    
}
export default ListPoolTimings