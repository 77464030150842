import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import Card from 'react-bootstrap/Card';

function PieChartGarden() {
  const [gardenData, setGardenData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch("http://localhost:8000/api/listGardenAppointment");
      const data = await res.json();
      setGardenData(data);
    };
    fetchData();
  }, []);

  let butterflyGardenCount = 0;
  let zenGardenCount = 0;
  let rockGardenCount = 0;
  for (let i = 0; i < gardenData.length; i++) {
    if (gardenData[i].garden_type === "Butterfly") {
      butterflyGardenCount += 1;
    } else if (gardenData[i].garden_type === "Zen") {
      zenGardenCount += 1;
    }
    else if (gardenData[i].garden_type === "Rock") {
        rockGardenCount += 1;
      }
  }

  const chartData = [butterflyGardenCount, zenGardenCount,rockGardenCount];
  console.log(chartData);

  const chartLabels = ["Butterfly Garden", "Zen Garden", "Rock Garden"];
  console.log(chartLabels);
  const chartColors = ["#E91E63", "#9C27B0", "#673AB7", "#3F51B5", "#2196F3", "#00BCD4"];
  return (
    <div className="pie-chart-container">
      <Card className="piesecuritycard">
        <Card.Body>
          <h1>Garden Apoointments Dashboard</h1>
          <Chart
            options={{
              title: {
                text: "",
              },
              labels: chartLabels,
              dataLabels: {
                enabled: true,
                formatter: function (val, opts) {
                  return opts.w.config.series[opts.seriesIndex];
                },
                background: {
                  enabled: true,
                  foreColor: "#fff",
                  borderRadius: 3,
                  padding: 5,
                  opacity: 0.9,
                  borderWidth: 1,
                  borderColor: "#fff",
                },
              },
              colors: chartColors,
            }}
            series={chartData}
            type="pie"
            width={500}
          />
          <Card.Text>
            This is the Generated report of Garden Appointments with number of residents and visitors in the complex
          </Card.Text>
          {/* <Button variant="primary">Go somewhere</Button> */}
        </Card.Body>
        <Card.Footer className="text-muted">Few Minutes Ago</Card.Footer>
      </Card>
      
    </div>

    
  );
}

export default PieChartGarden;