import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

function PiechartBuild() {
  const [managerData, setManagerData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch("http://localhost:8000/api/listManager");
      const data = await res.json();
      setManagerData(data);
    };
    fetchData();
  }, []);

  let StudioCount = 0;
  let poolCount = 0;
  let SecurityCount = 0;
  let GardenCount = 0;
  let TownhallCount =0;
  let CabinCount = 0;
  for (let i = 0; i < managerData.length; i++) {
    if (managerData[i].manager_type === "Studios") {
      StudioCount += 1;
      
    } else if (managerData[i].manager_type === "Townhall") {
        TownhallCount += 1;
      }
      else if (managerData[i].manager_type === "Cabins") {
        CabinCount += 1;
      }
     else if (managerData[i].manager_type === "Pool") {
      poolCount += 1;
    }
    else if (managerData[i].manager_type === "Security") {
        SecurityCount += 1;
      }
      else if (managerData[i].manager_type === "Garden") {
        GardenCount += 1;
      }
  }

  const chartData = [StudioCount,TownhallCount,CabinCount, poolCount,SecurityCount,GardenCount];
  console.log(chartData);

  const chartLabels = ["Studios","Townhall","Cabins", "Pool", "Security", "Garden"];
  console.log(chartLabels);

  const chartColors = ["#E91E63", "#9C27B0", "#673AB7", "#3F51B5", "#2196F3", "#00BCD4"];

  return (
    <div className="pie-chart-container">
      <Card className="piesecuritycard">
        <Card.Body>
          <h1>Building Manager Dashboard</h1>
          <Chart
            options={{
              title: {
                text: "",
              },
              labels: chartLabels,
              dataLabels: {
                enabled: true,
                formatter: function (val, opts) {
                  return opts.w.config.series[opts.seriesIndex];
                },
                background: {
                  enabled: true,
                  foreColor: "#fff",
                  borderRadius: 3,
                  padding: 5,
                  opacity: 0.9,
                  borderWidth: 1,
                  borderColor: "#fff",
                },
              },
              colors: chartColors,
            }}
            series={chartData}
            type="pie"
            width={500}
          />
          <Card.Text>
            This is the Generated report of Building Managers in the complex
          </Card.Text>
          {/* <Button variant="primary">Go somewhere</Button> */}
        </Card.Body>
        <Card.Footer className="text-muted">Few Minutes Ago</Card.Footer>
      </Card>
    </div>
  );
}

export default PiechartBuild;
