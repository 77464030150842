import Header from "./Header";
import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
// import { Link } from "react-router-dom";
function ListShiftTimings(){

    const [data,setData]=useState([]);
    useEffect( ()=>{
        
        fetchData();
    },[])
    console.warn("result",data)

    async function fetchData(){
        let result = await fetch("http://localhost:8000/api/listShifts");
        result = await result.json();
        setData(result)
        }


    return (

        <div>
            <Header/>
            <h4>List of Security Guards Timings</h4>
            <div className="col-sm-8 offset-sm-2">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Name</th>
                            <th>Employee Id</th>
                            <th>Start Timings</th>
                            <th>End Timings</th>
                            <th>Area</th>
                           
                            <th>Operations</th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((sec)=>
                                <tr>
                                    <th>{sec.id}</th>
                                    <th>{sec.name}</th>
                                    <th>{sec.emp_id}</th>
                                    <th>{sec.start_timing}</th>
                                    <th>{sec.end_timing}</th>
                                    <th>{sec.area}</th>

                                    <Link to={"/UpdateShiftTimings/"+sec.id}>
                                    <span  className="update">Update</span>
                                    </Link>
                                </tr>
                            )
                        }
                    </tbody>
                </Table> <br/> <br/> <br/>
 

            </div>
        </div>

    )


    
}
export default ListShiftTimings