import Header from "./Header";
import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function SecurityManager(){

    function handleChatClick() {
        // TODO: Add code to handle chat button click
        alert("Chat feature coming soon!");
      }
      

    

    return(

        <div>
            <Header/>
            <h1>Security Manager</h1>
            <div className="col-sm-6 offset-sm-3"><br/> <br/> <br/>
            <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/addsecurity.jpg')}/>
                    <Card.Body>
                        <Card.Title>Add Security Managers</Card.Title>
                        <Card.Text>
                           Add Security Managers for the Buildings
                        </Card.Text>
                        <Button href="/AddSecurity" variant="primary">Add Security Manager</Button>
                    </Card.Body>
                </Card>
            <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/listsecurity.jpg')}/>
                    <Card.Body>
                        <Card.Title>List of Security Managers</Card.Title>
                        <Card.Text>
                           List of all Security Managers at the Buildings
                        </Card.Text>
                        <Button href="/ListSecurity" variant="primary">List Managers</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/listresidents.jpg')}/>
                    <Card.Body>
                        <Card.Title>List of Residents</Card.Title>
                        <Card.Text>
                           List of all Residents to the Buildings such as Studios, Pool, Townhalls , Cabins and Garden
                        </Card.Text>
                        <Button href="/ListResidents" variant="primary">List Residents</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/listvisitor.png')}/>
                    <Card.Body>
                        <Card.Title>List of Visitors</Card.Title>
                        <Card.Text>
                           List of all Visitors to the Buildings such as Studios, Pool, Townhalls , Cabins and Garden
                        </Card.Text>
                        <Button href="/ListVisitors" variant="primary">List Visitors</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/timings.jpg')}/>
                    <Card.Body>
                        <Card.Title>List of Shift Timings</Card.Title>
                        <Card.Text>
                          List of all the Security shifts timings in the complex.
                        </Card.Text>
                        <Button href="/ListShiftTimings" variant="primary">List Shifts</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/addtimings.jpeg')}/>
                    <Card.Body>
                        <Card.Title>Add Shift Timings</Card.Title>
                        <Card.Text>
                          Add all the Security shifts timings in the complex.
                        </Card.Text>
                        <Button href="/ListShiftTimings" variant="primary">List Shifts</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/reports.png')}/>
                    <Card.Body>
                        <Card.Title>Generate Reports</Card.Title>
                        <Card.Text>
                        Generate Reports for all Securities to the Buildings 
                        </Card.Text>
                        <Button href="/PiechartSecurity" variant="primary">Generate Reports</Button>
                    </Card.Body>
                </Card>

                <Link id='chat-button' to="https://axa4926.uta.cloud/login.php" target="_blank">Chat with us</Link>


            
            </div>

        </div>

    )
  
}
export default SecurityManager