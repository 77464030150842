import Header from "./Header";
import React,{useState,useEffect} from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
function ListVisitors(){

    const [data,setData]=useState([]);
    useEffect( ()=>{
        
        fetchData();
    },[])
    console.warn("result",data)
   async function deleteOperation(id){
      let result= await fetch("http://localhost:8000/api/deleteVisitor/"+id,{
            method:'DELETE'
        });
        result= await result.json();
        console.warn(result)
        fetchData();
    }
    async function fetchData(){
        let result = await fetch("http://localhost:8000/api/listVisitor");
        result = await result.json();
        setData(result)
        }
    return (

        <div>
            <Header/>
            <h4>List of Visitors</h4>
            <div className="col-sm-8 offset-sm-2">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>DL Number</th>
                            <th>Driver Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>DOB</th>
                            <th>Guests</th>
                            <th>Car Plate Number</th>
                            <th>Entry Number</th>
                            <th>Operations</th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((item)=>
                                <tr>
                                    <th>{item.dl_number}</th>
                                    <th>{item.driver_name}</th>
                                    <th>{item.email}</th>
                                    <th>{item.mobile_number}</th>
                                    <th>{item.date_of_birth}</th>
                                    <th>{item.guests}</th>
                                    <th>{item.car_plate_number}</th>
                                    <th>{item.entry_number}</th>
                                    <th><span onClick={()=>deleteOperation(item.id)} className="delete">Delete</span>
                                    <Link to={"/UpdateVisitors/"+item.id}>
                                    <span  className="update">Update</span>
                                    </Link>
                                    </th>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>

            </div>
        </div>

    )


    
}
export default ListVisitors