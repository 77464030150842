import Header from "./Header";
import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";


function GardenManager(){


      

    

    return(

        <div>
            <Header/>
            <h1>Garden Manager</h1>
            <div className="col-sm-6 offset-sm-3"><br/> <br/> <br/>
            <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/gardenmanager.jpg')}/>
                    <Card.Body>
                        <Card.Title>Add Garden Managers</Card.Title>
                        <Card.Text>
                           Add Garden Managers for the Gardens in the Complex
                        </Card.Text>
                        <Button href="/AddGardens" variant="primary">Add Garden Manager</Button>
                    </Card.Body>
                </Card>
            <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/listgardens.jpg')}/>
                    <Card.Body>
                        <Card.Title>List of Garden Managers</Card.Title>
                        <Card.Text>
                           List of all Garden Managers of the Gardens in the complex
                        </Card.Text>
                        <Button href="/ListGardens" variant="primary">List Garden Manager</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/listresidents.jpg')}/>
                    <Card.Body>
                        <Card.Title>List of Residents</Card.Title>
                        <Card.Text>
                           List of all Residents Accessed the Garden in the complex
                        </Card.Text>
                        <Button href="/ListResidents" variant="primary">List Residents</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/listvisitor.png')}/>
                    <Card.Body>
                        <Card.Title>List of Visitors</Card.Title>
                        <Card.Text>
                           List of all Visitors Accessed the Garden in the complex
                        </Card.Text>
                        <Button href="/ListVisitors" variant="primary">List Visitors</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/timings.jpg')}/>
                    <Card.Body>
                        <Card.Title>List of Garden Timings</Card.Title>
                        <Card.Text>
                          List of all the Garden timings in the complex.
                        </Card.Text>
                        <Button href="/ListGardenTimings" variant="primary">List Timings</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={require('../images/reports.png')}/>
                    <Card.Body>
                        <Card.Title>Generate Reports</Card.Title>
                        <Card.Text>
                        Generate Reports for all Residents and Visitors in the Garden                        </Card.Text>
                        <Button href="/PieChartGarden" variant="primary">Generate Reports</Button>
                    </Card.Body>
                </Card>
                <Link id='chat-button' to="https://axa4926.uta.cloud/login.php" target="_blank">Chat with us</Link>


            
            </div>

        </div>

    )
  
}
export default GardenManager